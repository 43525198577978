@use './colors' as *;

$state-color-alternative-focus: $color-dark-orange;
$state-color-error: $color-red;
$state-color-focus: $color-orange;
$state-color-inactive-light: rgb(0 0 0 / 5%);
$state-color-success: $color-green;

/* stylelint-disable */
/* TODO: lower-case naming */
$stateColors: (
  AlternativeFocus: $state-color-alternative-focus,
  Error: $state-color-error,
  Focus: $state-color-focus,
  InactiveLight: $state-color-inactive-light,
  Success: $state-color-success,
);

:export {
  AlternativeFocus: $state-color-alternative-focus;
  Error: $state-color-error;
  Focus: $state-color-focus;
  InactiveLight: $state-color-inactive-light;
  Success: $state-color-success;
}
